const fontFamily = {
  base: '"Maison Neue", "Times New Roman", serif',
  // base: '"Maison Neue", "Times New Roman", serif',
};

export const fontWeight = {
  regular: 400,
  semiBold: 600,
  bold: 700,
};

const type = {
  exLargeTitle: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.base,
      fontSize: '32px',
      lineHeight: '40px',
      breakpoints: [
        {
          breakpointSize: 'md',
          fontSize: '48px',
          lineHeight: '58px',
        },
      ],
    },
  },
  h1: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.base,
      fontSize: '24px',
      lineHeight: '32px',
      breakpoints: [
        {
          breakpointSize: 'md',
          fontSize: '32px',
          lineHeight: '40px',
        },
      ],
    },
  },
  h2: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.base,
      fontSize: '24px',
      lineHeight: '28px',
    },
  },
  h3: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.base,
      fontSize: '20px',
      lineHeight: '24px',
    },
  },
  h4: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.base,
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  paragraph: {
    default: {
      fontWeight: fontWeight.regular,
      fontFamily: fontFamily.base,
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
  fieldLabel: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.base,
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  fieldPrompt: {
    default: {
      fontWeight: fontWeight.regular,
      fontFamily: fontFamily.base,
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  fieldInput: {
    default: {
      fontWeight: fontWeight.semiBold,
      fontFamily: fontFamily.base,
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
  labelRegular: {
    default: {
      fontWeight: fontWeight.regular,
      fontFamily: fontFamily.base,
      fontSize: '14px',
      lineHeight: '18px',
    },
  },
  labelSemiBold: {
    default: {
      fontWeight: fontWeight.semiBold,
      fontFamily: fontFamily.base,
      fontSize: '14px',
      lineHeight: '18px',
    },
  },
  small: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.base,
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
  a: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.base,
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
  smallButton: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.base,
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  largePrice: {
    default: {
      fontWeight: fontWeight.regular,
      fontFamily: fontFamily.base,
      fontSize: '38px',
      lineHeight: '38px',
      breakpoints: [
        {
          breakpointSize: 'md',
          fontSize: '48px',
          lineHeight: '48px',
        },
      ],
    },
  },
};

export default type;
export type ThemeFonts = typeof type;
export type ThemeFontsKeys = keyof typeof type;
